import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import { Colors } from "../../constants";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "40%",
    maxHeight: "75vh",
    zindex: 9999999,
  },
};

class FillPDFModal extends Component {
  render() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.props.isVisible}
        style={customStyles}
      >
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div></div>
            <div>
              <label
                className="labelModal"
                style={{ direction: "rtl", fontWeight: "bold", fontSize: 17 }}
              >
                חתימה ואישור
              </label>
            </div>
            <div>
              <i
                className="fa fa-times pointer"
                onClick={this.props.closeModal}
              ></i>
            </div>
          </div>

          <div style={{ direction: "rtl", marginTop: 20, textAlign: "right" }}>
            <Fragment>
              {/* eslint-disable-next-line */}
              {this.props.options.map((option, index) => {
                if (option.label === "תאריך מילוי המסמך") {
                  return (
                    <div className="form-group" key={index}>
                      <input
                        type="date"
                        name={option.label}
                        className="form-control form-control-user"
                        style={{ textAlign: "right" }}
                        id={option.label}
                        aria-describedby="emailHelp"
                        onChange={(e) => this.props.onChangeDynamictext(e)}
                        value={option.value}
                      />
                    </div>
                  );
                }
                if (option.label !== "" && option.isPresent !== false) {
                  return (
                    <div className="form-group" key={index}>
                      <input
                        type="text"
                        name={option.label}
                        className="form-control form-control-user"
                        style={{ textAlign: "right" }}
                        id={option.label}
                        aria-describedby="emailHelp"
                        placeholder={option.label}
                        onChange={(e) => this.props.onChangeDynamictext(e)}
                        value={option.value}
                      />
                    </div>
                  );
                }
              })}
              {/* eslint-disable-next-line */}
              {this.props.optionsSignature.map((option, index) => {
                if (option.label !== "") {
                  return (
                    <div className="form-group" key={index}>
                      <label>{option.label}</label>
                      <div>
                        <button
                          name={option.label}
                          value={option.label}
                          onClick={(e) => {
                            this.props.binddynamicSignautre(e, option);
                          }}
                          className="btn blue-button btn-same-width btn-document-header"
                        >
                          {option.isFilled ? "שנה חתימה" : "חתום כאן"}
                        </button>
                        {option.isFilled && (
                          <div>
                            <label style={{ fontSize: 13, color: "green" }}>
                              נחתם בהצלחה
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              })}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="text-center w-100">
                  <input
                    type="button"
                    className="btn btn-user btn-custom btn-block "
                    value="אישור"
                    style={{
                      width: "220px",
                      margin: "0 auto",
                      textAlign: "center",
                      color: "#fff",
                      borderColor: Colors.PRIMARY_BLUE,
                      backgroundColor: Colors.PRIMARY_BLUE,
                    }}
                    onClick={this.props.closeEditTextModal}
                  />
                </div>
              </div>
            </Fragment>
          </div>
        </div>
      </Modal>
    );
  }
}

export default FillPDFModal;
