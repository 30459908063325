import { GET_TEMPLATE_BY_SESSION_ID, SET_END_SESSION } from "../actionTypes";

const initialState = {
  sessionId: "",
  templateDetails: {
    data: [],
    previewHtml: "",
    previewHeader: "",
    previewFooter: "",
    session: {},
  },
};
export const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATE_BY_SESSION_ID:
      return {
        ...state,
        templateDetails: {
          data: [...action.payload?.data],
          previewHtml: action.payload?.previewHtml,
          previewHeader: action.payload?.previewHeader,
          previewFooter: action.payload?.previewFooter,
          session: action.payload?.session,
        },
      };
    case SET_END_SESSION:
      return {
        ...state,
        templateDetails: {
          ...state?.templateDetails,
          session: action?.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
